import { h, Fragment } from 'preact';
import { t } from 'i18next';

const DEFAULT_IMAGE_URL = 'https://images.sw.cdn.siemens.com/siemens-og.jpg';

const ResourceColumn = props => {
  const { enumeratedResources } = props;

  return (
    <Fragment>
      <h3 class="text-md text-600">{t('moreResources')}</h3>
      {enumeratedResources.map(enumeratedResource => {
        return (
          <Fragment>
            <a
              className="text-sm display-flex align-items-center m-b"
              href={enumeratedResource.localization.filter(l => l.locale === props.locale)[0].url}>
              <span className="flex-no-shrink" style={'width:95px'}>
                <span className="embed-responsive embed-responsive-1by1">
                  <img
                    className="embed-responsive-item"
                    loading="lazy"
                    src={`${
                      enumeratedResource.localization.filter(l => l.locale === props.locale)[0]
                        .imageUrl || DEFAULT_IMAGE_URL
                    }?w=190&fit=max&q=60&dpr=1&auto=format`}
                  />
                </span>
              </span>
              <span class="p-l">
                {enumeratedResource.localization.filter(l => l.locale === props.locale)[0].title}
              </span>
            </a>
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default ResourceColumn;
