import { h } from 'preact';
import ResourceRow from './ResourceRow';
import { useState, useEffect } from 'preact/hooks';
import ResourceColumn from './ResourceColumn';
import { DD_RUM, DD_LOGS } from '../../DD_RUM';
import { datadogRum } from '@datadog/browser-rum';
import i18next from 'i18next';

const APP_NAME = 'enumerated-resources';
const CDN_URL = 'https://static.sw.cdn.siemens.com';

DD_RUM();
DD_LOGS();
datadogRum.startSessionReplayRecording();

const App = props => {
  const { displaymode = 'row' } = props;
  const [enumeratedResources, setEnumeratedResources] = useState([]);

  const [error, setError] = useState(false);

  const rmIntegrationEndpoint = process.env.RM_INTEGRATION_ENDPOINT;
  const diswEnvironment = process.env.DISW_ENVIRONMENT;

  let resource = null;

  if (props.resource) {
    resource = readJSONFromProps(props, 'resource');
  } else {
    resource = JSON.parse(unescape(window._rmIntegrationPayload));
  }

  if (!resource) {
    setError(true);
  }

  useEffect(() => {
    fetch(`${rmIntegrationEndpoint}/v1/enumerated-resources`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': props.rmintegrationapikey
      },
      body: JSON.stringify(resource)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(JSON.stringify(response.json()));
        }
      })
      .then(data => {
        const dataFiltered = data.filter(resource => {
          return resource.localization.filter(l => l.locale === props.locale).length;
        });
        setEnumeratedResources(dataFiltered);
      })
      .catch(error => {
        console.error(error);
        setError(true);
      });
  }, []);

  useEffect(() => {
    const fetchTranslations = async () => {
      const response = await fetch(
        `${CDN_URL}/translations/${diswEnvironment}/${APP_NAME}/translation-${props.locale}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      const tokens = await response.json();
      const resources = {
        [tokens.locale]: {
          translation: tokens
        }
      };
      if (!i18next.isInitialized) {
        i18next.init({
          lng: props.locale,
          debug: diswEnvironment === 'dev',
          fallbackLng: 'en-US',
          resources,
          interpolation: {
            escapeValue: false
          }
        });
      }
    };
    fetchTranslations();
  }, []);

  if (error) {
    return null;
  }

  if (enumeratedResources.length) {
    return displaymode === 'column' ? (
      <ResourceColumn enumeratedResources={enumeratedResources} locale={props.locale} />
    ) : (
      <ResourceRow enumeratedResources={enumeratedResources} locale={props.locale} />
    );
  }
};

const readJSONFromProps = (props, propName) => {
  if (props[propName] && typeof props[propName] === 'string') {
    return JSON.parse(props[propName]);
  } else if (props[propName] && typeof props[propName] === 'object') {
    return props[propName];
  }
};

const unescape = escapedString => {
  return escapedString
    .replaceAll('&amp;', '&')
    .replaceAll('&lt;', '<')
    .replaceAll('&gt;', '>')
    .replaceAll('&quot;', '"')
    .replaceAll('&apos;', `'`);
};

export default App;
