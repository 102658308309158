import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

//track user interactions
export const DD_RUM = () => {
  return datadogRum.init({
    applicationId: process.env.DD_APPLICATION_ID,
    clientToken: process.env.DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'enumerated-resources',
    env: process.env.ENVIRONMENT, //respective deployment variable
    version: process.env.VERSION, //setting this as a repository variable for now
    //tracks 60% of sessions as Browser and not Browser premium
    sampleRate: 60,
    premiumSampleRate: 0,
    trackInteractions: true,
    useCrossSiteSessionCookie: false,
    trackSessionAcrossSubdomains: false,
    //mask all user related sensitive elements
    defaultPrivacyLevel: 'mask-user-input',
  });
};

// track browser logs
export const DD_LOGS = () => {
  return datadogLogs.init({
    applicationId: process.env.DD_APPLICATION_ID,
    clientToken: process.env.DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'enumerated-resources',
    env: process.env.ENVIRONMENT,
    version: process.env.VERSION,
    //tracks 60% of sessions as Browser and not Browser premium
    sampleRate: 60,
    premiumSampleRate: 0,
    trackInteractions: true,
    useCrossSiteSessionCookie: false,
    trackSessionAcrossSubdomains: false,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['warn', 'info'],
  });
};
