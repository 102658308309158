import { h } from 'preact';
import { t, exists } from 'i18next';

const MAX_ABSTRACT_CHARS = 230;
const DEFAULT_IMAGE_URL = 'https://images.sw.cdn.siemens.com/siemens-og.jpg';

const ResourceRow = props => {
  const { enumeratedResources, theme = 'dark' } = props;

  const translateResourceType = resourceName => {
    const formattedName = `resourceType_${resourceName}`;
    return exists(formattedName) ? t(formattedName) : resourceName;
  };

  return (
    <aside
      class="container border-t border-1 border-blue-dark p-y p-lg-y-lg"
      id="related"
      role="complementary"
      aria-labelledby="relatedHdr">
      <h2 class="header-focus text-xxl m-b-md m-lg-b-lg" id="relatedHdr">
        <span>{t('relatedResources')}</span>
      </h2>
      <div className="disw-resource-row" data-theme={theme}>
        {enumeratedResources.map(enumeratedResource => {
          return (
            <div>
              <div className="disw-card-resource">
                <div className="disw-card-resource-img">
                  <img
                    loading="lazy"
                    src={`${
                      enumeratedResource.localization.filter(l => l.locale === props.locale)[0]
                        .imageUrl || DEFAULT_IMAGE_URL
                    }?w=500&fit=max&q=60&dpr=1&auto=format`}
                    srcset={`${
                      enumeratedResource.localization.filter(l => l.locale === props.locale)[0]
                        .imageUrl || DEFAULT_IMAGE_URL
                    }?w=500&fit=max&q=50&dpr=2&auto=format, 
                  ${
                    enumeratedResource.localization.filter(l => l.locale === props.locale)[0]
                      .imageUrl || DEFAULT_IMAGE_URL
                  }?w=500&fit=max&q=40&dpr=3&auto=format,`}
                    alt={
                      enumeratedResource.localization.filter(l => l.locale === props.locale)[0]
                        .title
                    }
                  />
                </div>
                <div className="disw-card-resource-block">
                  <strong className="disw-card-resource-resource-type">
                    {translateResourceType(enumeratedResource.type)}
                  </strong>
                  <h3>
                    <a
                      href={
                        enumeratedResource.localization.filter(l => l.locale === props.locale)[0]
                          .url
                      }>
                      {
                        enumeratedResource.localization.filter(l => l.locale === props.locale)[0]
                          .title
                      }
                    </a>
                  </h3>
                  <p>
                    {enumeratedResource.localization.filter(l => l.locale === props.locale)[0]
                      .abstract.length > MAX_ABSTRACT_CHARS
                      ? `${enumeratedResource.localization
                          .filter(l => l.locale === props.locale)[0]
                          .abstract.slice(0, MAX_ABSTRACT_CHARS)}…`
                      : enumeratedResource.localization.filter(l => l.locale === props.locale)[0]
                          .abstract}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </aside>
  );
};

export default ResourceRow;
